.o-saleforces{
  .o-saleforces-banner{
    padding: 100px 0 $space_desktop;
    @media screen and (max-width: 767px) {
      padding: $space_tablet 0;
    }
    .container{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      gap: 40px;
      @media screen and (max-width: 991px) {
        flex-direction: column-reverse;
      }
    }
    .text{
      flex: 1;
      @media screen and (max-width: 991px) {
        flex-basis: 100%;
      }
      h1{
        margin-bottom: 20px;
        @media screen and (min-width: 768px) {
          font-size: 36px;
        }
        @media screen and (max-width: 767px) {
          font-size: 24px;
          margin-bottom: 16px;
        }
      }
      p{
        margin-bottom: 40px;
      }
    }
    .image{
      flex-basis: 30%;
      @media screen and (max-width: 991px) {
        flex-basis: 100%;
        width: 100%;
        text-align: center;
      }
      img{
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}
.o-saleforces-offer{
  margin-bottom: $space_desktop;
  @media screen and (max-width: 767px) {
    margin-bottom: $space_tablet;
  }
  h2{
    text-align: center;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      font-size: 30px;
    }
  }
  .o-saleforces-offer-top{
    p{
      text-align: center;
      padding: 0 58px;
      margin-bottom: 60px;
      @media screen and (max-width: 767px) {
        padding: 0;
        margin-bottom: 40px;
      }
    }
  }
  .offer-items{
    max-width: 930px;
    margin: 0 auto;
    &.two-columns{
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 70px 64px;
      .offer-item{
        flex-basis: calc(50% - 32px);
        margin-bottom: 0!important;
        @media screen and (max-width: 991px) {
          flex-basis: calc(100% - 32px);
        }
      }
    }
    .offer-item{
      display: flex;
      gap: 34px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
      &:not(:last-child){
        margin-bottom: 40px;
        @media screen and (max-width: 767px) {
          margin-bottom: 48px;
        }
      }
      .offer-img{
        width: 96px;
        height: 96px;
        border-radius: 15px;
        overflow: hidden;
      }
      .offer-text{
        flex: 1;
        h3{
          margin-bottom: 8px;
          @media screen and (max-width: 767px) {
            font-size: 20px;
            margin-bottom: 24px;
          }
        }
        p{
          margin-bottom: 10px;
        }
        .orange-link{
          color: $c_yellow_medium;
          font-size: 16px;
          font-weight: 600;
          line-height: 150%;
          text-decoration-line: underline;
        }
      }
    }
  }
}
.saleforces-form{
  background: #FBFBFB;
  padding: $space_desktop 0;
  margin-bottom: $space_desktop;
  @media screen and (max-width: 767px) {
    padding: $space_tablet 0;
    margin-bottom: $space_tablet ;
  }
  h2{
    text-align: center;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      font-size: 30px;
    }
  }
  .saleforces-form-description{
    text-align: center;
    margin-bottom: 50px;
    @media screen and (max-width: 767px) {
      margin-bottom: 42px;
    }
  }
  form{
    max-width: 570px;
    margin: 0 auto;
    .saleforces-full{
      margin-bottom: 24px;
    }
    .saleforces-form-top{
      margin-bottom: 24px;
      p{
        display: flex;
        align-items: center;
        gap: 24px;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        span{
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
    .wpcf7-form-control-wrap[data-name="email-156"]{
      margin-bottom: 24px;
      display: block;
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="file"]) {
      font-size: 16px;
      color: $c_text_100;
      background-color: #fff;
      padding: 18px 32px;
      border: 1px solid rgba(54, 54, 69, 0.1);
      border-radius: 12px;
      width: 100%;
      &::-webkit-input-placeholder { /* Edge */
        color: rgba($c_text_80, 0.5);
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba($c_text_80, 0.5);
      }

      &::placeholder {
        color: rgba($c_text_80, 0.5);
      }

      &:focus {
        border-color: $c_orange;
      }
    }
    textarea {
      font-size: 16px;
      color: $c_text_100;
      background-color: #fff;
      padding: 24px 32px 56px;
      border: 1px solid rgba(54, 54, 69, 0.1);
      border-radius: 12px;
      height: 180px;
      width: 100%;
      resize: none;
      margin-bottom: 24px;
      display: block;
      &::-webkit-input-placeholder { /* Edge */
        color: rgba($c_text_80, 0.5);
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba($c_text_80, 0.5);
      }

      &::placeholder {
        color: rgba($c_text_80, 0.5);
      }

      &:focus {
        border-color: $c_orange;
      }
    }
    input[type="submit"] {
      font-weight: 700;
      color: $c_white;
      background-color: #ffd36a;
      border-radius: 12px;
      padding: 10px 24px;
      border: 0;
      outline: none;
      transition: .3s all;

      &:hover {
        background-color: $c_orange;
      }
    }
    .attact-file {
      margin-bottom: 24px;
      position: relative;
      label {
        display: inline-flex;
        cursor: pointer;
        color: $c_text_100;
        &::before {
          content: url('../img/attach.svg');
          height: 24px;
          margin-right: 8px;
        }
      }
      .codedropz-upload-handler{
        display: none;
      }
      .dnd-upload-status{
        .dnd-upload-image{
          display: none;
        }
        .dnd-upload-details{
          padding-left: 0;
          width: 100%;
          .name{
            align-items: center;
            span{
              font-family: 'Mont';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 1;
              color: $c_text_100;
            }
            em{
              font-family: 'Mont';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 1;
              color: $c_orange;
            }
          }
          .remove-file{
            top: 8px;
            span{
              &:after{
                background-image: url('../img/close.png');
              }
            }
          }
          .dnd-progress-bar{
            span{
              background: $c_orange;
            }
          }
        }
      }
      input,
      br {
        display: none;
      }
    }
    .wpcf7-acceptance {
      input {
        display: none;

        &:checked {
          & + .wpcf7-list-item-label {
            &::before {
              animation: none;
            }
            &::after {
              opacity: 1;
            }
          }
        }
      }
      .wpcf7-list-item {
        margin: 0;
      }
      .wpcf7-list-item-label {
        max-width: 280px;
        display: block;
        position: relative;
        color: $c_text_100;
        padding-left: 30px;

        &::before {
          position: absolute;
          top: 14px;
          left: 0;
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          border: 2px solid $c_orange;
          border-radius: 4px;
          box-shadow: 0 0 0 rgba($c_orange, 0.4);
          animation: pulse 2s infinite;
        }

        &::after {
          position: absolute;
          top: 10px;
          left: 4px;
          content: url('../img/acceptance-check.svg');
          opacity: 0;
        }
      }
      a {
        color: $c_orange;

        &:hover {
          color: $c_orange;
        }
      }
    }
    .bottom {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .accept {
        max-width: 260px;

        a {
          font-weight: 600;
          color: #ffb248;
          border-bottom: 1px solid #ffb248;
          transition: .3s all;

          &:hover {
            color: $c_yellow_medium;
            border-color: $c_yellow_medium;
          }
        }
      }

      br {
        display: none;
      }
      .submit-wrap{
        position: relative;
        @media screen and (max-width: 479px) {
          margin: 15px 0;
        }
        .wpcf7-spinner{
          position: absolute;
          top: calc(100% + 10px);
          left: calc(50% - 24px);
          @media screen and (max-width: 479px) {
            top: calc(50% - 12px);
            left: calc(100% + 12px);
          }
        }
      }
      .ajax-loader {
        display: none;
      }
    }
    .wpcf7-response-output {
      display: none !important;
    }

    .wpcf7-not-valid {
      border-color: red !important;
    }
    .wpcf7-not-valid-tip{
      text-align: center;
      cursor: pointer;
      padding-top: 16px;
    }
  }
}
.saleforces-related{
  margin-bottom: 40px;
  @media screen and (max-width: 479px) {
    margin-bottom: $space_mobile;
  }
  h2{
    @media screen and (min-width: 768px) {
      font-size: 30px;
    }
  }
}